// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
}

.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $yellow;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background: #fff;
	padding: 0;
	box-shadow: 3px 3px 6px #00000029;
	border-radius: 0;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	display: none;
}

.search-book .tommy-zeb-wrapper * h2 {
	font-size: 18px;
	font-weight: 700;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn-primary;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: $primary;
	font-size: 20px;
	font-weight: 700;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: $secondary;
	font-weight: 700;
}

@media (min-width: 1200px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		position: relative;
		min-height: 250px;
		overflow: hidden;
	}
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
		width: auto;
		position: absolute !important;
		max-width: 200%;
	}
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}

@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}

tommy-search-and-book > div {
	@media(min-width: 992px) {
		display: flex;
		align-items: flex-start;
	}

	.tommy-zeb-left {
		position: sticky;
		top: 95px;
	}
}


.tommy-zeb-wrapper .tommy-zeb-result-image {
	@media(min-width: 992px) {
		flex: 0 0 250px;
		max-width: 250px;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result {
	@media(min-width: 992px) {
		display: flex;
		align-items: stretch;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
	@media(min-width: 992px) {
		flex: 0 0 calc(100% - 250px);
		max-width: calc(100% - 250px);
	}
	padding: 15px 25px 15px;
}

.search-book .tommy-zeb-wrapper * h2 {
	font-size: .875rem;
}

.tommy-zeb-wrapper .tommy-zeb-left select,
.tommy-zeb-wrapper .tommy-zeb-left input {
	border-radius: 0;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	@extend h1;
	font-size: 30px;
	font-family: $font-family-brygadad !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	background: transparent;
	font-size: 1rem;
	background: $dark-blue;
	color: #fff;
	padding: 10px 10px;

	&:after {
		font-size: 16px;
		@extend .arrow-behind;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {

}

.tbs-calendar .tbs-day.tbs-vertrekdag-bezet::after, .tbs-calendar .tbs-day.tbs-beschikbaar.tbs-vertrekdag-bezet:after,
.tbs-calendar .tbs-day.tbs-aankomstdag-bezet:after, .tbs-calendar .tbs-day.tbs-beschikbaar.tbs-aankomstdag-bezet:after {
	background: #423c46;
}


