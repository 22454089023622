&.contact {
	.form {
		margin-top: 15px;
		background: $yellow;
		border: none;

		label {
			font-weight: 700;
			font-size: .875rem;
		}

		input {
			border-radius: 0;
		}

		.form-group {
			&:last-of-type {
				text-align: center;
			}
		}
	}
}
