// old base file

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.mini-sab .tommy-zeb-wrapper {
	float: none;
	width: auto;
}

.mini-sab .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
	font-size: 14px;
	float: none !important
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
	flex-wrap: wrap;
	float: none;
	background: none;
	color: $black;
	padding: 0 !important;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left label {
	color: $black;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
	justify-content: center !important;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	margin-right: 15px;
	padding-right: 0;
	border-right: none;
}

.mini-sab .tommy-zeb-wrapper-mini h2.ng-binding {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label {
	display: none;
}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	display: block !important;
}
tommy-search-and-book-mini[data-language="fr"]{
	.tommy-zeb-accommodation-type-choice::before {
	content: "Type de logement"!important;

}}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
	display: block;
	content: 'Type accommodatie';
	line-height: 30px;
	font-size: 12px;
	font-weight: 400;
}



.fr .mini-sab.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
	content: "Type de logement";
}


.de .mini-sab.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
	content: 'Unterkunft';
}

.en .mini-sab.tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
	content: 'Accommodation';
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
	max-width: 200px;
	min-width: 150px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select {
	width: 120px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input {
	line-height: 20px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding: 5px;
	margin: 0;
	width: 100%;
}

.mini-sab .tommy-zeb-period {
	display: flex !important;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin {
	margin-right: 15px;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label {
	float: none !important;
	display: block;
}

.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label {
	margin-bottom: 0 !important;
	line-height: 30px;
	display: block;
	font-size: 12px;
	font-weight: 400;
}

.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
	padding: 0;
	float: left;
	margin-bottom: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
	border-right: none;
	padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 4px;
	padding: 6px 10px;
	height: 36px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select {
	padding-top: 4px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
	width: 130px;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
	min-width: 120px;
	margin: 30px 0 0 0 !important;
	padding: 8px 20px;
	border-radius: 4px;
	border-width: 3px;
	border-color: $tertiary;
	background-color: $tertiary;
	color: #fff;
	font-size: 15px;
	line-height: 20px;
	text-transform: none;
	box-shadow: 0 3px 6px rgba($black, 0.15);
	font-weight: 600;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update:hover {
	border-color: $secondary;
	background-color: $secondary;
}

/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
	display: block;
	margin: 0;
	color: $black;
	font-size: 12px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
	text-transform: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after {
	display: none;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
	display: flex;
	min-width: auto;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
	border: 1px solid #fff;
	border-radius: 4px;
	padding: 6px;
	width: 50px !important;
	height: 36px;
	margin-top: 0 !important;
	margin-left: 15px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
	z-index: 99;
	margin-top: 37px;
	background-color: #fff;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #000;
	width: 100%;
	max-width: 100%;
	min-width: 260px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope {
	padding: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
	flex-wrap: nowrap;
	margin: 5px 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-label {
	flex: 1 1 auto;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
	flex: 0 0 85px;
	align-self: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
	min-width: 20px;
	line-height: 22px;
	text-align: center;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
	background-color: $primary;
	color: #fff;
	line-height: 22px;
	transition: all 0.5s ease;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
	background-color: $secondary;
	color: #fff;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span {
	line-height: 24px;
	top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
	height: 36px;
	padding: 7px 14px;
	color: #000;
	font-weight: 400;
	border-radius: 3px;
	text-transform: none;
	background-color: #fff;
	border: 1px solid #fff;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn {
	margin-top: 0 !important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories::after {
	font-family: "Font Awesome 5 Pro";
	content: "\f107";
	margin-left: 15px;
}


@media (min-width: 768px) and (max-width: 1199px) {

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto;
	}

}

@media (max-width: 767px) {

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left {
		display: block;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
		border-bottom: none;
		padding: 0;
		margin: 0;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
	.mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope {
		float: none;
		clear: both;
	}
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		margin: 0 !important;
	}
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
		width: auto;
	}
	.mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update {
		display: block;
	}

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select {
		max-width: none;
	}
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
	.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
		display: block !important;
		width: 100% !important;
	}

	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
		margin-top: 15px !important;
	}
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
		margin-right: 0;
	}

}
