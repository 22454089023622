// override variables after vendors like bootstrap are loaded


$white: #fff;

$green: #90C25A;
$dark-green: #2E500A;

$dark-blue: #123250;
$blue: #006EAE;

$black: #000;

$light-brown: #F3ECD8;

$grey: #343434;

$yellow: #E9B500;

$radius: 20px;


