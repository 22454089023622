.btn {
	box-shadow: 0 3px 6px rgba($black, 0.15);
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;

	/* btn-primary */
	&.btn-primary {
		background: transparent;
		border-color: $blue;
		color: $blue;
		font-size: 14px;
		border-width: 2px;
		border-radius: 3px;
		padding: 6px 45px;
		text-transform: none;

		&:hover {
			background: $blue;
			border-color: $blue;
			color: $white;
		}

		&:after {
			@extend .arrow-behind
		}
	}

	/* btn-outline-primary */
	&.btn-outline-primary {
		&:hover {
		}
	}

	/* btn-secondary */
	&.btn-secondary {
		&:hover {

		}
	}

	/* btn-outline-secondary */
	&.btn-outline-secondary {
		&:hover {
		}
	}

}

.owl-carousel .item .owl-btn {
	background: #fff;
	border-color: #fff;
}

/*  btn-back
    ========================================================================== */
.btn-back {
	display: inline-block;

	text-decoration: none;
	color: $dark-blue;
	font-size: .875rem;

	&::before {
		content: '\f177';
		display: inline-block;
		font-weight: 500;
	}
}

.btn-next {
	display: inline-block;
	text-decoration: none;
	color: $dark-blue;
	font-size: .875rem;

	&:before {
		font-family: 'Font Awesome 5 Pro';
		content: '\f178';
		font-weight: 500;
	}
}

/*  btn-down
    ========================================================================== */
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.wysiwyg {
	a.btn {
		margin: 25px 0;
	}
}

