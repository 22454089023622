&.service-overview,
&.service-category-detail {
	.service-overview.collection,
	.service-category-overview {
		@extend .three-columns-block;
	}

	.card-title-link {
		margin-top: 15px !important;
	}
}

.faciliteiten-blokken {
	.card.icon {
		.card-image {
			min-height: 96px;
			margin-bottom: 15px;
		}

		.card-body {
			.card-caption {
				align-items: flex-start;
			}
		}
	}
}

