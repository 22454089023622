// headings
.arrow-behind {
	font-family: 'Font Awesome 5 Pro';
	content: '\f178';
	margin-left: 7.5px;
	text-decoration: none;
}


.owl-carousel .item .owl-title {
	@include media-breakpoint-up(lg) {
		font-size: 60px;
	}
	font-family: $font-family-brygadad;
}

h1 {
	@include media-breakpoint-up(lg) {
		font-size: 36px;
	}
	font-family: $font-family-headings;
	@include media-breakpoint-up(xl) {
		font-size: 48px;
	}
}

h2 {
	@include media-breakpoint-up(lg) {
		font-size: 40px;
	}
	font-family: $font-family-headings;
	@include media-breakpoint-up(xl) {
		font-size: 40px;
	}
}

h3 {
	@include media-breakpoint-up(lg) {
		font-size: 30px;
	}
	font-family: $font-family-headings;
}

h4 {
	@include media-breakpoint-up(lg) {
		font-size: 24px;
	}
	font-family: $font-family-headings;
}

h5 {
	@include media-breakpoint-up(lg) {
		font-size: 14px;
	}
	font-family: $font-family-headings;
}

h6 {
	@include media-breakpoint-up(lg) {
		font-size: 12px;
	}
	font-family: $font-family-headings;
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

&.default {
	background-color: $light-brown;
	@include media-breakpoint-up(xl) {
		padding-top: 220px;
	}
	@media(max-width: 1199px) {
		padding-top: 80px;
	}

	&.sticky {
		padding-top: 85px;
	}
}

.content-section,
.lead-section {
	h1, h2, h3, h4, h5, h6 {
		color: $dark-blue;
	}

	.wysiwyg,
	p {
		color: $black;
	}

	.wysiwyg p {
		margin-bottom: 0;
		font-size: 1.125rem;
	}
}

.booking-section,
.content-section {
	&.green-background {
		background-color: $green !important;
		margin-left: 15px !important;
		margin-right: 15px !important;
		border-radius: $radius !important;
		padding: 75px 15px !important;

		h1, h2, h3, h4, h5, h6, .card .card-caption .card-title {
			color: $dark-green;
		}

		.card .card-caption .card-title {
			margin-top: 15px;

			&:after {
				@extend .arrow-behind;
			}
		}

		.wysiwyg,
		p {
			color: $white;
		}

		.btn-primary {
			color: $white;
			border-color: $white;
		}
	}
}

.booking-section {
	@extend .green-background;
}

.mini-sab {
	.container .container-holder {
		background-color: $green;
	}
}

a {
	color: $blue;
	text-decoration: none;

	&:hover {
		color: $green;
		opacity: 1;
	}
}

@media(min-width: 991px) {
	.collection {
		margin-bottom: 35px;

		&.two-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 50%;
					max-width: 50%
				}
			}
		}

		&.three-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 33.333%;
					max-width: 33.333%;

					.card-image {
						background: #fff;
						padding: 10px 10px 0;
					}
				}
			}
		}

		&.four-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 25%;
					max-width: 25%;
				}
			}
		}

		&.five-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 20%;
					max-width: 20%;
				}
			}
		}

		&.six-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 16.6666666667%;
					max-width: 16.6666666667%;
					padding: 0 9px;
				}
			}
		}
	}
}

@media(max-width: 767px) {
	.collection {
		&.mobile-two-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}
		}
	}
}

.smaller-text-section {
	.container-holder {
		margin-bottom: 0;

		.wysiwyg {
			p {
				font-size: 1rem;
				color: #343434;

				strong {
					font-size: 1rem;
				}

				a {
					&:after {
						@extend .arrow-behind;
					}
				}
			}
		}
	}
}

ul.social-icons-wrapper {
	margin-bottom: 0;
	justify-content: center;
	flex-flow: row wrap;
	width: 100%;

	li {
		a {
			background: $white;
			border-radius: 50px;
			display: inline-flex;
			width: 46px;
			height: 46px;
			align-items: center;
			justify-content: center;

			&:hover {
				background: $blue;
				color: $white;
			}

			i {
				font-size: 28px;
			}

			span {
				display: none;
			}
		}
	}
}

.lead-section {
	.info {
		.subtitle {
			display: none;
		}
	}
}

&.accommodation-category-detail,
&.blog-post-overview {
	.collection.grid {
		@extend .three-columns-block;
	}
}

.wysiwyg {
	ul, ol {
		padding-left: 0;
		list-style: none;

		li {
			padding-left: 25px;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	ul {
		li {
			&:before {
				content: '•';
				color: $dark-blue;
				font-size: 60px;
				line-height: 1rem;
				top: -2px;
			}
		}
	}

	ol {
		counter-reset: ol-count;

		li {

			&:before {
				counter-increment: ol-count;
				content: counter(ol-count);
				font-weight: 600;
				font-size: 1rem;
				color: $dark-blue;
			}
		}
	}
}

ul, ol {
	li {
		font-size: 1rem;
		color: #343434;
	}
}

ul.custom-list li::before {
	font-weight: 500 !important;
	color: $dark-blue !important;
	font-size: 1rem;
}

ul.custom-list-extra li::before {
	color: $dark-blue;
}

main.main {
	.container-two-columns {
		.container-holder {
			margin-left: 0;
			margin-right: 0;

			> .column {
				@include media-breakpoint-up(lg) {
					flex: 1 1 calc(50% - 3rem);
					max-width: 100%;
					padding: 0 !important;
				}

				&:empty {
					display: none !important;
				}

				&:not(:empty) {
					+ .column {
						@include media-breakpoint-up(lg) {
							margin-left: 6rem;
						}
					}
				}
			}
		}
	}
}

.mobile-slider .owl-stage {
	display: flex;
}

.mobile-slider {
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-nav, .owl-dots {
		display: none;
	}
}
