.eyecatcher {
	padding-left: 15px;
	padding-right: 15px;

	.back-to-overview-link {
		display: block;
		position: relative;
		top: 75px;
		padding-left: 40px;
		z-index: 9002;
	}

	@media(max-width: 767px) {
		.eyecatcher-owl-carousel {
			max-height: 300px;
			border-radius: 20px;
			overflow: hidden;
		}
	}
}

&.sticky {
	.eyecatcher {
		.back-to-overview-link {
			z-index: 9;
		}
	}
}

.eyecatcher-owl-carousel {
	.owl-stage-outer {
		border-radius: $radius;
	}
}

.eyecatcher.large {
	@media(max-width: 767px) {
		min-height: 270px;
		max-height: 270px;
	}

	> .eyecatcher-owl-carousel,
	> .eyecatcher-owl-carousel .item {
		@media(max-width: 767px) {
			min-height: 270px;
			max-height: 270px;
			overflow: hidden;
		}
	}

	.owl-carousel .item {
		.owl-title {
			max-width: 710px;
			margin-left: auto;
			margin-right: auto;
			@media(max-width: 767px) {
				font-size: 1.5rem;
			}
		}

		.owl-sub-title {
			@media(max-width: 767px) {
				font-size: 1.35rem;
			}
		}
	}

	@media(max-width: 991px) {
		.owl-dots {
			visibility: hidden;
			pointer-events: none;
		}
	}
}


.owl-carousel {
	.item {
		min-height: 500px;
	}

	.owl-dots {
		bottom: 70px;
	}

	.item {
		@media(max-width: 767px) {
			min-height: 270px !important;
			max-height: 270px;
			padding: 0;
		}
	}

	&.large {
		@media(max-width: 767px) {
			min-height: 270px !important;
			max-height: 270px;
			padding: 0;
		}
	}
}

.owl-carousel .owl-dots .owl-dot.active span {
	background: #fff;
}

