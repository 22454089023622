// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-brygadad: 'Brygada 1918', serif;
$font-family-base: $font-family-montserrat;
$font-family-headings: $font-family-brygadad;

// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;

// colors
$primary: #90C25A;
$secondary: #3D4609;
$tertiary: #006EAE;
$quaternary: #044389;

$light: #90C25A;
$dark: #3d4609;

// matrix-colors
$matrix-color-primary: #90C25A;
$matrix-color-secondary: #3D4609;
$matrix-color-tertiary: #006EAE;
$matrix-color-quaternary: #044389;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1070px,
	xxl: 1070px,
	xxxl: 1070px
) !default;


/*
$body-bg: #FFF !default;
$body-color: #000 !default;
$body-color--dark: #fff !default;
$link-color: $primary !default;
$link-color--hover: darken($link-color, 15%) !default;

// font
$font-family-base: 'Helvetica', sans-serif !default;

// typography
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$headings-font-family: $font-family-base !default;
$headings-font-style: $font-style-base !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $body-color !default;

$color-h1: $body-color !default;
$color-h2: $body-color !default;
$color-h3: $body-color !default;
$color-h4: $body-color !default;
$color-h5: $body-color !default;
$color-h6: $body-color !default;

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;



$grid-breakpoints-small: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths-small: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1300px,
  xxxl: 1600px
) !default;

// header
$header-logo-height: 50px !default;
$header-logo-height--lg: 80px !default;
$header-logo-margin: 20px !default;

// card
$card-bg--dark: #000 !default;

// carousel
$carousel-min_height: 35vh !default;
$carousel-min_height--lg: 70vh !default;
*/
