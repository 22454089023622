/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	margin: 0;
	padding: 6vh 0;
}

/* 	lead-section
    ========================================================================== */
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 810px;
			margin: 0 auto;
			text-align: center;
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}

			.label {
			}
		}

		// assortiment
		.discount-price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.former-price {
			text-decoration: line-through;

			.amount {
				font-size: $font-size-sm;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.downloads {
			.label {
			}

			.file {
			}
		}
	}
}

/* 	content-section
    ========================================================================== */
.content-section {
	margin: 6vh 0;
}

/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
	margin: 6vh 0;
}

/* 	bundle-detail-section
    ========================================================================== */
.bundle-detail-section {
	margin: 6vh 0;
}

.accommodation-category-overview {
	@extend .three-columns-block;
}

.booking-section {
	> .container {
		max-width: 1120px;
	}
}
