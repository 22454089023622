// new base file - january 2022

#TommyBookingSupport {
	color: $dark-blue;
}

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
	color: $dark-blue;
	background: transparent;
}

#TommyBookingSupport .tbs-btn-info {
	background-color: $secondary;
	border-color: $secondary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-info:hover {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-success:hover {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}

#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: 0.5s;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	background-color: #E9B500 !important;
}

body.default #TommyBookingSupport .tbs-legenda .tbs-day {
	background: #fff;
}

body.default .tbs-calendar .tbs-week:hover {
	background: #E9B500;
}

.tbs-calendar .tbs-day.tbs-vertrekdag:before,
.tbs-legenda .tbs-day.tbs-bezet, .tbs-calendar .tbs-day.tbs-bezet,
.tbs-calendar .tbs-day.tbs-aankomstdag:before,
.tbs-legenda .tbs-day.tbs-aankomstdag:before,
.tbs-legenda .tbs-day.tbs-vertrekdag:before {
	background: #423C46 !important;
}

#TommyBookingSupport .tbs-legenda .tbs-day, .tbs-calendar .tbs-day {
	background: #fff;
}

.tbs-calendar .tbs-week:hover {
	background: #423C46;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-nietbeschikbaar {
	background: #423C46;

	div {
		color: $white;
	}
}

body.default #TommyBookingSupport .tbs-legenda {
	padding: 0;
}

// include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
	display: flex !important;
	align-items: center;
	height: 50px !important;
}

#TommyBookingSupport .tbs-navbar-nav > li > a {
	font-size: .875rem;
	font-weight: 700;
	color: $dark-blue;
	padding: 0 10px;
}

#TommyBookingSupport .tbs-navbar-nav > li:not(:first-of-type) {
	border-left: 1px solid $dark-blue;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
	border-radius: 0;

	.tbs-navbar-nav {
		display: flex;
	}
}

#TommyBookingSupport * a {
	text-decoration: none;
}

#TommyBookingSupport .tbs-legenda {
	background: none;
	border: none;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
	padding: 20px 0;
	margin: 20px 0;
	background-color: transparent; // custom
	border-radius: 5px;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 33px 0;
	padding: 20px;
}

#TommyBookingSupport .tbs_overlay-mask {
	border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
	margin: 20px 0;
}

#TommyBookingSupport .tbs-calendar .tbs-day {
	background: $white;
	border: 1px solid $green;
	border-radius: 0;
}

.tbs-calendar .tbs-day div {
	color: $dark-blue;
	font-weight: 700;
	text-decoration: none;
	font-size: 14px;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
	cursor: pointer;
	transition: all 0.5s ease;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	opacity: 0.8;
}

#TommyBookingSupport .tbs-calendar table > thead > tr > th {
	border: none;
	font-size: 14px;
	font-weight: 300;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	color: $dark-blue;
	background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
	margin-bottom: 0;
}

#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
	margin: 0;
}

#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
	margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
	color: #E91E63;
	background-color: rgba(233, 30, 99, 0.09);
	border: none;
	margin: 0 15px 15px;
	padding: 10px 35px 10px 10px;
}

#TommyBookingSupport .tbs-has-error .tbs-form-control {
	border-color: rgb(244, 67, 54);
}

#TommyBookingSupport .tbs-form-control {
	border-radius: 0;
	font-weight: 700;
	font-size: 16px;
	color: $dark-blue;

	&#maanden {
		text-align: center;
		font-weight: 300;
		font-size: 14px;
	}
}

#TommyBookingSupport .tbs_persoonscategorien .tbs-form-control {
	max-width: 200px;
	height: 24px;
	padding: 0 15px;
	line-height: 15px;
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs-label {
	font-size: 100%;
	margin-right: 5px;
}

#TommyBookingSupport .tbs_kassabon .tbs-hr {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
	font-weight: 400;
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
	font-size: 32px;
	line-height: 50px;
}

#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row {
	margin: 0 0 15px 0;
}

/* resonsive mobile */
@media (max-width: 767px) {

	#TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
	#TommyBookingSupport .tbs-calendarNext span.tbs-translate {
		display: none;
	}

}


#TommyBookingSupport .tbs-btn-default,
#TommyBookingSupport .tbs-btn-block, {
	border: none;
	background: transparent;
	color: $dark-blue;
	opacity: 1;

	&.tbs-calendarPrevious {
		i {
			font-size: 20px;
			@extend .btn-back;
		}
	}

	&.tbs-calendarNext {
		i {
			font-size: 20px;
			@extend .btn-next;
		}
	}
}

#TommyBookingSupport .tbs-table {
	border-spacing: 0;
}

#TommyBookingSupport .tbs-table thead > tr:last-of-type th {
	border-top: 1px solid $dark-blue;
	border-bottom: 1px solid $dark-blue;
}

@media(min-width: 991px) {
	#TommyBookingSupport .tbs_kalenders > div {
		padding-right: 65px;
	}
	#TommyBookingSupport .tbs-container-fluid .tbs-row .tbs-col-md-8 {
		padding-right: 0;
		width: 58%;
	}
	#TommyBookingSupport .tbs-container-fluid .tbs-row .tbs-col-md-4 {
		width: 42%;
		padding-left: 80px;
	}
}

#TommyBookingSupport .tbs-legenda .tbs-day {
	background: #fff;
}

#TommyBookingSupport .tbs-legenda {
	padding: 0;
}

#TommyBookingSupport .tbs-legenda-beschikbaar {
	display: none;
}

#TommyBookingSupport .tbs_talen {
	display: none;
}


#TommyBookingSupport .tbs_kassabon {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.booking-section h2 {
	margin-bottom: 25px;
}

.booking-section > .container {
	max-width: 1165px;
}

.tbs_totaal_overzicht h2 span,
.tbs_totaal_overzicht h2 {
	color: $dark-blue !important;
	font-size: 30px;
	font-weight: 700 !important;
}

#TommyBookingSupport .tbs-btn-success {
	@extend .btn;
	@extend .btn-primary;
	color: $blue !important;
	border: 2px solid $blue !important;
	font-size: 14px;
	text-transform: none;
	width: auto;

	&:after {
		@extend .arrow-behind;
	}

	&:hover {
		color: #fff !important;
		border-color: #fff !important;
	}
}

#TommyBookingSupport .tbs_kassabon .tbs-row .tbs-text-right {
	text-align: center;
}

#TommyBookingSupport .tbs-row.tbs_kassabon_overzicht h2 {
	font-family: $font-family-montserrat;
	font-size: 16px;
	color: $dark-blue;
	text-align: left;
}

#TommyBookingSupport .tbs-row.tbs_kassabon_overzicht {
	margin-top: 25px;
}

#TommyBookingSupport .tbs_kassabon_overzicht > .tbs-col-xs-12 > .tbs-row:not(:first-of-type) > div {
	color: #343434;

	&:last-of-type {
		text-align: right;
	}
}

@media(max-width: 991px) {
	.tbs_kassabon .tbs_totaal_overzicht .tbs-totaal {
		background: transparent;

		span {
			color: #fff;
		}
	}
}

.tbs-calendar .tbs-day.tbs-bezet div {
	color: #fff;
}
