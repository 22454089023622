header.header {
	transition: none;
	@include media-breakpoint-down(lg) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
	}

	&:not(.sticky) {
		> .container-fluid {
			.column.default {
				background-color: transparent;
			}
		}
	}

	.container-holder {
		> .column.default {
			display: flex;
			flex-flow: row wrap;
			@include media-breakpoint-down(xl) {
				justify-content: space-between;
			}
			@include media-breakpoint-down(lg) {
				height: 80px;
			}

			#default_menu {
				flex: 0 0 calc(100% - 600px);
				max-width: calc(100% - 600px);
				justify-content: flex-start;
				order: 2;
				@media(max-width: 1199px) {
					position: absolute;
					left: 0;
					top: 70px;
					width: 100vw;
					max-width: 100vw;

					> .navbar-nav {
						background: #F3ECD8;
					}
				}

				li {
					a {
						span {
							color: $dark-blue;
							font-weight: 700;
							font-size: 1rem;
							@media(max-width: 1299px) and (min-width: 992px) {
								font-size: 0.9rem;
							}
						}
					}
				}
			}

			> .page-block {
				max-height: 100%;

				&.vocabulary-term-list {
					flex: 0 0 auto;
					max-width: 195px;
					width: 100%;
					order: 2;
					@media(max-width: 991px) {
						justify-content: flex-end;
					}

					> ul {
						margin-bottom: 0;

						li {
							@media(max-width: 991px) {
								margin-right: 0;
							}
						}
					}

					a {
						width: 100%;
						background: $dark-blue;
						border: 1px solid $dark-blue;
						padding: 0.375rem 0.75rem;
						box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
						border-radius: 3px;
						margin: 1rem 0;
						display: inline-block;

						span {
							color: $white;
							font-weight: 700;
							text-transform: none;

							&:after {
								@extend .arrow-behind;
							}
						}

						&:hover {
							background: transparent;

							span {
								color: $dark-blue;
							}
						}
					}
				}

				&.logo {
					order: 1;
					width: 100%;
					max-width: 405px;
					@media(max-width: 1200px) {
						max-width: 200px;
					}
					@media(max-width: 991px) {
						width: calc(100% - 240px);
						max-width: 200px;
						z-index: 9;
					}

					> a {
						width: 100%;

						img {
							width: 100%;
							height: auto;
							max-height: none;
						}
					}
				}
			}
		}
	}

	&.sticky {
		.container-holder {
			> .column.default {
				#default_menu {
					flex: 0 0 calc(100% - 400px);
					max-width: calc(100% - 400px);
				}

				> .page-block {
					&.link {
						order: 4;
					}

					&.logo {
						max-width: 200px;
					}
				}
			}
		}
	}

	.navbar-toggler {
		@media(max-width: 991px) {
			position: relative;
			right: 10px;
		}

		.navbar-toggler-icon {
			> i {
				&:before {
					color: $dark-blue;
				}
			}
		}
	}

	.site-switcher {
		position: absolute;
		right: 250px;
		@media(max-width: 1399px) and (min-width: 1200px) {
			top: 45px;
			right: 35px;
		}
		@media(max-width: 991px) {
			right: 20px;
			top: 80px;
		}

		a {
			color: $dark-blue;
		}
	}

	* {
		transition: none !important;
	}
}

&:not(.sticky) {
	.back-to-overview-link {
		z-index: 9001;
		position: relative;
	}
}
