&.accommodation-detail {
	.lead-section {

		.info-nav {
			align-items: baseline !important;
		}

		.info {
			display: flex;
			flex-flow: row wrap;

			.title {
				flex: 0 0 100%;
				max-width: 100%;
			}

			.properties {
				justify-content: flex-start !important;
				order: -1;

				li {
					&:first-of-type {
						margin-left: 0;
					}

					span {
						color: #123250;
						font-size: 14px;
						font-weight: 300;
					}
				}
			}

			h1 {
				font-size: 24px;
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.wysiwyg p {
				font-size: 18px;
			}
		}
	}
}
