.card {
	text-decoration: none;
	box-shadow: 0 3px 6px #00000029;
	transition: .3s;

	.card-body {
		.card-caption {
			.card-title {
				font-family: $font-family-headings;
				color: $dark-blue;
			}

			.card-description {
				font-size: 0.875rem;
				color: #343434;
			}
		}

		.card-buttons {
			.card-btn {
				background: transparent;
				color: $blue;
				font-size: 14px;
				box-shadow: none;
				border: none;
				font-weight: 500;
				text-transform: none;

				&:after {
					@extend .arrow-behind
				}
			}
		}
	}

	&.icon {
		padding-top: 25px;

		.card-body {
			padding: 5px;

			.card-caption {
				display: flex;
				align-items: center;
				@include media-breakpoint-down(xl) {
					justify-content: center;
				}

				.card-title {
					font-size: 18px;
					color: $blue;
					text-align: center;
				}
			}
		}
	}
}

.green-background {
	.card {
		background: transparent;
		box-shadow: none;
		border: none;

		.card-body {
			background: transparent;
			padding: 0;
		}
	}
}

.grid-items {
	.item {
		transition: .3s;

		.card-image {
			overflow: hidden;

			.card-img {
				transition: .3s;
			}
		}

		&:hover {
			box-shadow: none;

			.card-image {
				.card-img {
					transform: scale(1.05);
				}
			}
		}
	}
}
