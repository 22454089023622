> footer > section {
	&.footer-logos {
		padding: 4vh 0;
	}

	&.footer-content {
		padding: 4vh 0;
	}

	&.footer-links {
		padding: 4vh 0;
	}

	&.footer-socket {
		padding: 1vh 0;
	}
}


footer.footer {
	background: $dark-blue;
	margin-top: 65px;
	@include media-breakpoint-down(xl) {
		margin-top: 85px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 155px;
	}

	h3 {
		color: $yellow;
	}

	h6 {
		@extend .small-footer-text;
	}

	.footer-list {
		> h5 {
			font-size: 18px;
			color: #fff;
		}

		a {
			@extend .small-footer-text;

			&:hover {
				color: $green;
			}
		}
	}

	p {
		color: $white;
	}

	.btn.btn-primary {
		background-color: $blue;
		color: $white;

		&:hover {
			color: $blue;
			background-color: $white;
		}
	}

	.first-row {
		.column {
			&.one {
				@include media-breakpoint-up(lg) {
					padding-right: 0 !important;
				}
			}

			&.two {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				margin-top: -130px;
				@include media-breakpoint-down(xl) {
					order: -1;
				}
			}
		}
	}

	.second-row {
		.column {
			&.one {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;

				.footer-list {
					@include media-breakpoint-down(lg) {
						flex: 0 0 50%;
						max-width: 50%;
						margin-bottom: 15px;
					}
				}
			}
		}
	}

	.footer-socket {
		.list {
			a {
				@extend .small-footer-text;
				font-weight: 400;

				&:hover {
					color: $green;
				}
			}
		}
	}
}

.small-footer-text {
	font-family: $font-family-montserrat;
	color: #ffffff70;
	font-weight: 500;
	font-size: 12px;
}
